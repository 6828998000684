import React from 'react';
import PropTypes from 'prop-types';

function BrandCard({ data }) {
  if (!data) {
    return null;
  }

  return (
    <div className="brand-card">
      <img src={data?.Logo?.publicURL} alt={data?.Logo?.alternativeText} width="auto" height="90" />
      {data?.TiklaParamEarnings && (
        <div className="brand-card-advantages">
          <i className="icon-star-filled brand-card-advantages-icon" />
          <div
            className="brand-card-advantages-text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: data?.TiklaParamEarnings,
            }}
          />
        </div>
      )}
    </div>
  );
}

BrandCard.propTypes = {
  data: PropTypes.object,
};

export default BrandCard;
