export const TG_BRAND = {
  BURGER_KING: '354d0e4f-c4f5-e211-bc3c-005056af03f4',
  USTA_PIDECI: 'ea553690-695b-e911-941a-0050568045bb',
  USTA_DONERCI: '3f4d0e4f-c4f5-e211-bc3c-005056af03f4',
  POPEYES: '394d0e4f-c4f5-e211-bc3c-005056af03f4',
  ARBYS: '3b4d0e4f-c4f5-e211-bc3c-005056af03f4',
  SBARRO: '374d0e4f-c4f5-e211-bc3c-005056af03f4',
  AMASYA_ET_1: '46d3187d-80aa-e711-80d2-0050568e5e5d',
  AMASYA_ET_2: '5edb25cd-05be-e711-9419-0050568045bb',
};

export const TA_BRAND = {
  BURGER_KING: 1,
  USTA_PIDECI: 2,
  USTA_DONERCI: 4,
  POPEYES: 5,
  ARBYS: 6,
  SBARRO: 7,
};

export const EXTERNAL_BRAND = {
  SERAS: '62c33390-16fd-11ec-9621-0242ac130002',
  MILGO: '6191e59c-1058-4988-8cd1-d1c2965261f3',
};
