import { cloneDeep } from 'lodash';
import { EXTERNAL_BRAND, TG_BRAND } from '../enums/brand.enum';

export const getAllBrands = (staticBrands, externalBrands) => {
  const amasyaEt = staticBrands?.find(
    (element) => element?.node?.CRMId === TG_BRAND.AMASYA_ET_1 || element?.node?.CRMId === TG_BRAND.AMASYA_ET_2,
  );
  const strapiMilgo = externalBrands?.find((element) => element?.node?.BrandId === EXTERNAL_BRAND.MILGO)?.node;
  const milgo = cloneDeep(amasyaEt);
  milgo.node.Logo.publicURL = strapiMilgo.Logo.url;
  milgo.node.DisplayOrder += 1;
  milgo.node.tabVal = '627f2f9f-c187-eb11-9429-005056800c1e';
  return [...staticBrands, milgo]?.sort((a, b) => (a?.node?.DisplayOrder > b?.node?.DisplayOrder ? 1 : -1));
};
