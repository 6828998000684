import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import BrandCard from './brandCard';
import { getAllBrands } from '../utils/milgo';
import { analyticsBrandSelectionView } from '../utils/analyticsUtils';
import Link from './Link';

const RestaurantsBox = ({ staticBrands, externalBrands }) => {
  const [allBrands] = useState(() => getAllBrands(staticBrands, externalBrands));

  useEffect(() => {
    analyticsBrandSelectionView(allBrands);
  }, []);

  return (
    <>
      <h5 className="page-title">Sipariş verebileceğiniz restoranlar</h5>
      <Row style={{ margin: '0 -5px' }}>
        {allBrands &&
          allBrands.map((brand) => (
            <Col xs={6} sm={4} md={3} className="m-0 p-2" key={brand?.node?.Name}>
              <Link
                to={`/${brand?.node?.NormalizedName}`}
                state={{ tabVal: brand?.node?.tabVal }}
                className="restaurant-box-link"
              >
                <BrandCard data={brand?.node} />
              </Link>
            </Col>
          ))}
      </Row>
    </>
  );
};

RestaurantsBox.propTypes = {
  staticBrands: PropTypes.array,
  externalBrands: PropTypes.array,
};

export default RestaurantsBox;
